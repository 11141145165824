"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const $ = require("jquery");
const utils_1 = require("./utils");
function isValidJSON(text) {
    try {
        JSON.parse(text);
        return true;
    }
    catch (_a) {
        return false;
    }
}
function formatContentTypeWithData(data, method = 'GET') {
    if (method === 'GET') {
        return {
            data,
        };
    }
    if (data instanceof FormData) {
        return {
            data,
            contentType: false,
            processData: false,
        };
    }
    if (isValidJSON(data)) {
        return {
            data,
            contentType: 'application/json',
        };
    }
    return {
        data,
    };
}
class HttpService {
    constructor() {
        this.token = localStorage.getItem('token');
        this.dmToken = localStorage.getItem('dm_token');
    }
    static init(IBL_API_CONFIG, GLOBAL_HANDLERS) {
        HttpService.url = IBL_API_CONFIG.IBL_API_PROXY_URL;
        IBL_API_CONFIG['IBL_AXD_URL'] = IBL_API_CONFIG['IBL_AXD_URL']
            ? IBL_API_CONFIG['IBL_AXD_URL']
            : IBL_API_CONFIG['IBL_DM_URL'];
        HttpService.urlConfig = IBL_API_CONFIG;
        HttpService.globalHandlers = GLOBAL_HANDLERS;
    }
    _buildRequestObject(method = 'GET', path, data, includeAuthorizationHeader, customUrlKey, successCb = null, errorCb = null) {
        const url = customUrlKey && HttpService.urlConfig[customUrlKey]
            ? HttpService.urlConfig[customUrlKey] + path
            : HttpService.url + path;
        const requestObject = Object.assign(Object.assign({ url, type: method }, formatContentTypeWithData(data, method)), { success: function (_data) {
                (0, utils_1.cacheMechanism)(path, data, _data);
                successCb && successCb(_data);
            }, error: function (error) {
                var _a;
                if ((0, utils_1.isTokenExpired)()) {
                    (_a = HttpService.globalHandlers) === null || _a === void 0 ? void 0 : _a.errors[401]();
                }
                errorCb && errorCb(error);
            } });
        if (['IBL_LMS_URL', 'IBL_CMS_URL'].includes(customUrlKey)) {
            requestObject['xhrFields'] = {
                withCredentials: true,
            };
            const csrfCookie = (0, utils_1.getCookie)('csrftoken');
            if (csrfCookie) {
                requestObject['headers'] = {
                    'X-Csrftoken': csrfCookie,
                };
            }
        }
        if (includeAuthorizationHeader) {
            let authorizationValue;
            if (customUrlKey === 'IBL_DM_URL') {
                authorizationValue = `Token ${localStorage.getItem('dm_token')}`;
            }
            else if (customUrlKey === 'IBL_AXD_URL') {
                authorizationValue = `Token ${localStorage.getItem('axd_token')}`;
            }
            else {
                authorizationValue = `Bearer ${localStorage.getItem('token')}`;
            }
            requestObject.beforeSend = (xhr) => {
                xhr.setRequestHeader('Authorization', authorizationValue);
            };
        }
        return requestObject;
    }
    post(path, data = null, successCb = null, errorCb = null, includeAuthorizationHeader = true, customUrlKey = null) {
        (0, utils_1.cacheMechanism)(path, data);
        $.ajax(this._buildRequestObject('POST', path, data, includeAuthorizationHeader, customUrlKey, successCb, errorCb));
    }
    patch(path, data = null, successCb = null, errorCb = null, includeAuthorizationHeader = true, customUrlKey = null) {
        (0, utils_1.cacheMechanism)(path, data);
        $.ajax(this._buildRequestObject('PATCH', path, data, includeAuthorizationHeader, customUrlKey, successCb, errorCb));
    }
    get(path, data = null, successCb = null, errorCb = null, includeAuthorizationHeader = true, customUrlKey = null) {
        const cachedData = (0, utils_1.cacheMechanism)(path, data);
        if (cachedData) {
            successCb(cachedData);
            return;
        }
        $.ajax(this._buildRequestObject('GET', path, data, includeAuthorizationHeader, customUrlKey, successCb, errorCb));
    }
}
exports.default = HttpService;
