import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { clearCookies, api } from '@iblai/ibl-web-react-common';
import { utils } from '@iblai/ibl-web-core-frontend';
const Logout = () => {
  const location = useLocation();
  const handleLogout = (redirectTo) => {
    fetch(`${process.env.REACT_APP_IBL_LMS_URL}/logout`, {
      method: 'POST',
      credentials: 'include',
    }).then(() => {
      localStorage.clear();
      clearCookies();
      window.location.href = redirectTo;
    });
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectTo = queryParams.get('redirect-to');
    if (utils.getCookie('csrftoken')) {
      handleLogout(redirectTo);
    } else {
      api.ibledxplatform.getCsrfToken(
        300,
        (_) => handleLogout(redirectTo),
        (error) => console.error(error)
      );
    }
  }, []);
};

export { Logout };
