/**
 * @namespace dliedxcourses
 */

/**
 * This callback type is called `successCb` and is called when an api request is successful.
 * @callback successCb
 * @param {Object} data Data is passed to the success callback function
 */

import HttpService from '../http';
import endpoint from '../config';

const http = new HttpService();

/**
 * Retrieves the price of a course
 * @kind function
 * @name getCoursePrice
 * @memberof dliedxcourses
 *
 * @param {Object} data
 * @param {String} data.course_id Course ID
 * @param {callback} successCb
 *
 * @example <caption>Get the price of a course</caption>
 * This usage:
 *
 * ```js
 * dliedxcourses.getCoursePrice(
 *    {course_id: "course-v1:IBL+1+1"},
 *    (data) => console.log(data)
 * )
 * ```
 */
export function getCoursePrice(data, successCb = null) {
  http.get(
    endpoint.DLI_EDX.GET_COURSE_PRICE,
    data,
    (data) => {
      if (successCb) {
        successCb(data);
      }
    },
    (error) => {
      console.error('Error occurred:', error);
    },
    false,
    'IBL_LMS_URL'
  );
}

/**
 * Retrieves the session url of a course
 * @kind function
 * @name getSessionUrl
 * @memberof dliedxcourses
 *
 * @param {Object} data
 * @param {String} data.course_id Course ID
 * @param {String} data.token Token
 * @param {callback} successCb
 *
 * @example <caption>Get the session url of a course</caption>
 * This usage:
 *
 * ```js
 * dliedxcourses.getSessionUrl(
 *    {
 *        course_id: "course-v1:IBL+1+1",
 *        token: "example",
 *    },
 *    (data) => console.log(data)
 * )
 * ```
 */
export function getSessionUrl(data, successCb = null) {
  return `${
    HttpService.urlConfig['IBL_LMS_URL']
  }${endpoint.DLI_EDX.GET_SESSION_URL(data.course_id, data.session_token)}`;
}
