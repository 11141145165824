import api from '../../../utils/api';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function getParentDomain(domain) {
  if (!domain) {
    return '';
  }
  const parts = domain.split('.');
  return parts.length > 1 ? `.${parts.slice(-2).join('.')}` : domain;
}

const CompleteLogin = ({ isMobileApp }) => {
  const location = useLocation();
  useEffect(() => {
    if (isMobileApp) {
      // return to mobile app with the authorization code
      const queryParams = new URLSearchParams(location.search);
      window.location.href = `ibl-apps://${
        window.location.hostname
      }?code=${queryParams.get('code')}`;
    } else {
      api.ibledxtenants.getUserTenants(
        (tenants) => {
          api.iblutils.saveUserTenantsDataToLocalStorage(tenants);
          if (tenants.length) {
            const formData = new FormData();
            formData.append('platform_key', localStorage.getItem('tenant'));
            api.ibldmauth.getToken(formData, ({ data }) => {
              api.iblwebauth.initializeLocalStorageWithAuthData(
                data.axd_token,
                data.dm_token,
                data.user,
                tenants
              );
              const redirectTo = localStorage.getItem('redirectTo');
              if (redirectTo) {
                document.cookie = `userData=${encodeURIComponent(
                  localStorage.userData
                )}; domain=${getParentDomain(
                  window.location.hostname
                )}; path=/`;
                window.location.href = `${redirectTo}/sso-login?data=${encodeURIComponent(
                  JSON.stringify({ ...localStorage })
                )}`;
              }
            });
          } else {
            console.error('User is not linked to a platform');
          }
        },
        (error) => console.error('An error occurred!!', error)
      );
    }
  }, []);
  return <></>;
};

export { CompleteLogin };
