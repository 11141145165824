import {
  createBrowserRouter,
  isRouteErrorResponse,
  RouterProvider,
  useRouteError,
  json,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import '@iblai/ibl-web-react-common/dist/index.css';
import { Error403 } from '@iblai/ibl-web-react-common';
import { Logout } from './pages/Logout';
// import Error404 from './errors/pages/error404';
// import Error503 from './errors/pages/error503';
import './App.css';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import api from './utils/api';

import { AuthContext } from './utils/context';
import Login, { CompleteLogin } from './pages/Login';
import CreateOrganization from './pages/CreateOrganization';
import { areSubdomainsOfSameParentDomain } from './utils/utils';
import { inIframe } from '@iblai/ibl-web-react-common';
import MobileSso from './pages/Login/MobileSso/MobileSso';

function App() {
  const [authData] = useState(() => ({ ...localStorage }));
  useEffect(() => {
    if (!inIframe()) {
      api.ibledxplatform.getCsrfToken(300);
    }
    api.initiateAxdClientsWithToken(authData.axd_token);
    if (authData.axd_token && authData.dm_token) {
      window.parent.postMessage(
        {
          reason: 'TOKEN',
          message: {
            axdToken: {
              token: authData.axd_token,
              expires: authData.axd_token_expires,
            },
            dmToken: {
              token: authData.dm_token,
              expires: authData.dm_token_expires,
            },
            user: JSON.parse(authData.userData),
            tenants: JSON.parse(authData.tenants),
          },
        },
        '*'
      );
    } else if (inIframe()) {
      window.parent.postMessage(
        {
          reason: 'TOKEN',
          message: null,
        },
        '*'
      );
    }
    appReady();
  }, []);
  const ErrorPage = () => {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        // return <Error404 />;
        return <div>404</div>;
      } else if (error.status === 403) {
        return <div>403</div>;
      }
    }
  };
  const appReady = () => {
    window.parent.postMessage(
      {
        reason: 'IFRAME_READY',
      },
      '*'
    );
  };
  return (
    <AuthContext.Provider value={authData}>
      <RouterProvider
        router={createBrowserRouter([
          {
            path: '/',
            errorElement: <ErrorPage />,
            loader: ({ params, request }) => {
              const url = new URL(request.url);
              const authDomain = url.hostname;
              if (url.searchParams.get('redirect-to')) {
                const redirectToDomain = new URL(
                  url.searchParams.get('redirect-to')
                ).hostname;
                if (
                  redirectToDomain &&
                  !areSubdomainsOfSameParentDomain(authDomain, redirectToDomain)
                ) {
                  throw json(
                    { message: 'Unauthorized Redirect' },
                    { status: 403 }
                  );
                }
              }

              return [];
            },
            children: [
              {
                path: 'login',
                element: <Login />,
              },
              {
                path: 'logout',
                element: <Logout />,
              },
              {
                path: 'mobile/login/complete',
                element: <CompleteLogin isMobileApp={true} />,
              },
              {
                path: 'mobile/sso',
                element: <MobileSso />,
              },
              {
                path: 'login/complete',
                element: <CompleteLogin />,
              },
              {
                path: 'signup',
                element: <Signup />,
              },
              {
                path: 'forgot-password',
                element: <ForgotPassword />,
              },
              {
                path: 'reset-password',
                element: <ResetPassword />,
              },
              {
                path: 'create-organization',
                element: <CreateOrganization />,
              },
            ],
          },
        ])}
      />
    </AuthContext.Provider>
  );
}
export default App;
