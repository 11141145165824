import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { api } from '@iblai/ibl-web-react-common';
import { utils } from '@iblai/ibl-web-core-frontend';

export default function MobileSso() {
  const location = useLocation();
  const handleLogout = (queryParams) => {
    fetch(`${process.env.REACT_APP_IBL_LMS_URL}/logout`, {
      method: 'POST',
      credentials: 'include',
    }).then(() => {
      localStorage.clear();
      window.location.href = `${
        process.env.REACT_APP_IBL_LMS_URL
      }${decodeURIComponent(
        queryParams.get('sso-url')
      )}&next=${encodeURIComponent(queryParams.get('next'))}`;
    });
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (utils.getCookie('csrftoken')) {
      handleLogout(queryParams);
    } else {
      api.ibledxplatform.getCsrfToken(
        300,
        (_) => handleLogout(queryParams),
        (error) => console.error(error)
      );
    }
  });
}
