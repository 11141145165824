"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchAPI = void 0;
class SearchAPI {
    constructor(baseURL) {
        this.baseURL = baseURL;
    }
    apiCall(endpoint_1) {
        return __awaiter(this, arguments, void 0, function* (endpoint, params = {}) {
            const url = new URL(this.baseURL + endpoint);
            Object.keys(params).forEach(key => {
                if (key === 'facet') {
                    url.searchParams.append(key, JSON.stringify(this.cleanFacets(params[key])));
                }
                else {
                    url.searchParams.append(key, JSON.stringify(params[key]));
                }
            });
            try {
                const response = yield fetch(url.toString());
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status} - ${response.statusText}`);
                }
                return response.json();
            }
            catch (error) {
                console.error('Error making API call:', error);
                throw error;
            }
        });
    }
    cleanFacets(facets) {
        const cleanedFacets = {};
        Object.keys(facets).forEach(category => {
            const { total, terms, other } = facets[category];
            if (total > 0) {
                const cleanedTerms = {};
                Object.keys(terms).forEach(term => {
                    if (terms[term] > 0) {
                        const capitalizedTerm = (term === 'instructor-led' || term === 'self-paced')
                            ? this.capitalize(term)
                            : term;
                        cleanedTerms[capitalizedTerm] = terms[term];
                    }
                });
                cleanedFacets[category] = {
                    total,
                    terms: cleanedTerms,
                    other
                };
            }
        });
        return cleanedFacets;
    }
    capitalize(text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
    singleIndexSearch(index, filter, pageSize = 100, page = 1) {
        const params = {
            filter: Object.assign({ _index: index }, filter),
            page_size: pageSize,
            page: page
        };
        return this.apiCall('', params);
    }
    facetSearch(facets, pageSize = 100, page = 1) {
        const params = {
            facet: facets,
            page_size: pageSize,
            page: page
        };
        return this.apiCall('', params);
    }
    multipleIndicesSearch(indices, page = 1, context = '', pageSize = 100) {
        const params = {
            multiple_indices: indices,
            page: page,
            context: context,
            page_size: pageSize
        };
        return this.apiCall('', params);
    }
    specificFacetSearch(facets, index, pageSize = 100, page = 1) {
        const params = {
            filter: { _index: index },
            facet: facets,
            page_size: pageSize,
            page: page
        };
        return this.apiCall('', params);
    }
    documentDetail(index, detailScope, pageSize = 100, page = 1) {
        const params = {
            detail_indice: { _index: index },
            detail_scope: detailScope,
            page_size: pageSize,
            page: page
        };
        return this.apiCall('', params);
    }
}
exports.SearchAPI = SearchAPI;
