import { useState } from "react";

const useNotification = () => {
    const [notifications, setNotifications] = useState({});

    const notify = (headerMsg, displayMsg, type) => {
        console.log(notifications);
        setNotifications({...notifications, [Object.keys(notifications).length]: {headerMsg, displayMsg, type}});
    }

    const onClose = (key) => {
        const {[key]: remove, ...newNotifications} = notifications;
        setNotifications(newNotifications);
    }

    return [notifications, notify, onClose];
}

export default useNotification;