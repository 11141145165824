/**
 * @namespace dliedxevents
 */

/**
 * This callback type is called `successCb` and is called when an api request is successful.
 * @callback successCb
 * @param {Object} data Data is passed to the success callback function
 */

import HttpService from '../http';
import endpoint from '../config';

const http = new HttpService();

/**
 * Enrolls to event
 * @kind function
 * @name postEventEnroll
 * @memberof dliedxevents
 *
 * @param {Object} data
 * @param {String} data.code Event code
 * @param {callback} successCb
 *
 * @example <caption>Enroll to event</caption>
 * This usage:
 *
 * ```js
 * dliedxevents.postEventEnroll(
 *    {code: "example"},
 *    (data) => console.log(data)
 * )
 * ```
 */
export function postEventEnroll(data, successCb = null) {
  return `${
    HttpService.urlConfig['IBL_LMS_URL']
  }${endpoint.DLI_EDX.POST_EVENT_ENROLL(data['code'])}`;
}
