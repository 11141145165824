"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserProgramStatus = exports.getPlatformConfigSitePublic = exports.fetchUserPlatformLink = exports.redeemProgramInvitations = exports.createProgramBulkInvitations = exports.redeemCourseInvitations = exports.createCourseBulkInvitations = exports.redeemPlatformInvitations = exports.createPlatformBulkInvitations = exports.fetchProgramInvitations = exports.fetchCourseInvitations = exports.fetchPlatformInvitations = exports.updateCorePlatformConfigSite = exports.getCorePlatformUsers = exports.createCoreUsersPlatforms = exports.createCatalogInvitationsProgram = exports.createCatalogInvitationsCourse = exports.createCatalogInvitationsPlatform = void 0;
/**
 * @namespace ibldmplatform
 */
const http_1 = require("../http");
const config_1 = require("../config");
const http = new http_1.default();
/**
 * Invite a user to a platform
 * @function createCatalogInvitationsPlatform
 * @memberof ibldmplatform
 * @see https://github.com/ibleducation/ibl-dm-catalog-invitations-app/blob/master/USAGE.md#platform-invitation-create
 *
 * @param {Object} data
 * @param {String} data.username User's username
 * @param {String} data.email User's email
 * @param {String} data.platform_key Platform key
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Invite a user with email student@example.com to a platform</caption>
 * This usage:
 *
 * ```js
 * ibldmplatform.createCatalogInvitationsPlatform(
 *    {email: "student@example.com", "platform_key": ""},
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function createCatalogInvitationsPlatform(data, successCb = null, errorCb) {
    http.post(config_1.default.IBL_DM.INVITE_USER_TO_PLATFORM, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, true, 'IBL_DM_URL');
}
exports.createCatalogInvitationsPlatform = createCatalogInvitationsPlatform;
function createCatalogInvitationsCourse(data, successCb = null, errorCb) {
    http.post(config_1.default.IBL_DM.INVITE_USER_TO_COURSE, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, true, 'IBL_DM_URL');
}
exports.createCatalogInvitationsCourse = createCatalogInvitationsCourse;
function createCatalogInvitationsProgram(data, successCb = null, errorCb) {
    http.post(config_1.default.IBL_DM.INVITE_USER_TO_PROGRAM, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, true, 'IBL_DM_URL');
}
exports.createCatalogInvitationsProgram = createCatalogInvitationsProgram;
/**
 * Link a user to a platform
 * @function createCoreUsersPlatforms
 * @memberof ibldmplatform
 *
 * @see https://github.com/ibleducation/ibl-dm-pro/blob/v2/USAGE.md#user-platform-linking-view
 *
 * @param {Object} data
 * @param {String} data.username User's username
 * @param {String} data.key Platform key
 * @param {String} [data.added_on]
 * @param {String} [data.expired_on]
 * @param {String} [data.is_admin=true]
 * @param {String} [data.active=true]
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Link a user with username "student" to a platform "tenant"</caption>
 * This usage:
 *
 * ```js
 * ibldmplatform.createCoreUsersPlatforms(
 *    {username: "student", key: "tenant"},
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 * @example <caption>Remove a user with username "student" from a platform "tenant"</caption>
 * This usage:
 *
 * ```js
 * ibldmplatform.createCoreUsersPlatforms(
 *    {username: "student", key: "tenant" is_active: false},
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function createCoreUsersPlatforms(data, successCb = null, errorCb) {
    http.post(config_1.default.IBL_DM.LINK_USER_TO_PLATFORM, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    });
}
exports.createCoreUsersPlatforms = createCoreUsersPlatforms;
/**
 * Get users in a platform
 * @function getCorePlatformUsers
 * @memberof ibldmplatform
 *
 * @see https://github.com/ibleducation/ibl-dm-pro/blob/v2/USAGE.md#user-platform-management-list-view
 *
 * @param {Object} data
 * @param {String} data.key Platform key
 * @param {String} [data.query]
 * @param {String} [data.page]
 * @param {String} [data.page_size]
 * @param {String} [data.sort="id"]
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Get users in platform "tenant"</caption>
 * This usage:
 *
 * ```js
 * ibldmplatform.getCorePlatformUsers(
 *    {key: "tenant"},
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function getCorePlatformUsers(data, successCb = null, errorCb = null) {
    http.get(config_1.default.IBL_EDX.GET_PLATFORM_USERS, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false, 'IBL_LMS_URL');
}
exports.getCorePlatformUsers = getCorePlatformUsers;
/**
 * Update the platform config
 * @function updateCorePlatformConfigSite
 * @memberof ibldmplatform
 *
 * @see https://github.com/ibleducation/ibl-dm-pro/blob/v2/USAGE.md#platform-site-config-update
 *
 * @param {Object} data
 * @param {String} data.key Platform key
 * @param {String} [data.field_key] Config field to update
 * @param {String} [data.value] Config value for the field to update
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Update name for the platform "tenant" to "Tenant B"</caption>
 * This usage:
 *
 * ```js
 * ibldmplatform.updateCorePlatformConfigSite(
 *    {key: "tenant", "field_key": "name", "value": "Tenant B"},
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function updateCorePlatformConfigSite(data, successCb = null, errorCb = null) {
    http.post(config_1.default.IBL_EDX.UPDATE_PLATFORM_CONFIG, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, true, 'IBL_DM_URL');
}
exports.updateCorePlatformConfigSite = updateCorePlatformConfigSite;
function fetchPlatformInvitations(data, callback = null) {
    http.get(config_1.default.IBL_DM.FETCH_PLATFORM_INVITATIONS, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.fetchPlatformInvitations = fetchPlatformInvitations;
function fetchCourseInvitations(data, callback = null) {
    http.get(config_1.default.IBL_DM.FETCH_COURSE_INVITATIONS, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.fetchCourseInvitations = fetchCourseInvitations;
function fetchProgramInvitations(data, callback = null) {
    http.get(config_1.default.IBL_DM.FETCH_PROGRAM_INVITATIONS, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    });
}
exports.fetchProgramInvitations = fetchProgramInvitations;
function createPlatformBulkInvitations(data, callback = null) {
    http.post(config_1.default.IBL_DM.CREATE_PLATFORM_BULK_INVITATIONS, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_LMS_URL');
}
exports.createPlatformBulkInvitations = createPlatformBulkInvitations;
function redeemPlatformInvitations(data, callback = null) {
    http.post(config_1.default.IBL_DM.REDEEM_PLATFORM_INVITATIONS, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.redeemPlatformInvitations = redeemPlatformInvitations;
function createCourseBulkInvitations(data, callback = null) {
    http.post(config_1.default.IBL_DM.CREATE_COURSE_BULK_INVITATIONS, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.createCourseBulkInvitations = createCourseBulkInvitations;
function redeemCourseInvitations(data, callback = null) {
    http.post(config_1.default.IBL_DM.REDEEM_COURSE_INVITATIONS, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.redeemCourseInvitations = redeemCourseInvitations;
function createProgramBulkInvitations(data, callback = null) {
    http.post(config_1.default.IBL_DM.CREATE_PROGRAM_BULK_INVITATIONS, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.createProgramBulkInvitations = createProgramBulkInvitations;
function redeemProgramInvitations(data, callback = null) {
    http.post(config_1.default.IBL_DM.REDEEM_PROGRAM_INVITATIONS, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.redeemProgramInvitations = redeemProgramInvitations;
function fetchUserPlatformLink(data, callback = null) {
    http.get(config_1.default.IBL_DM.FETCH_USER_PLATFORM_LINK, data, (data) => {
        callback && callback(data);
    }, (error) => {
        console.error('Error occurred:', error);
    }, true, 'IBL_DM_URL');
}
exports.fetchUserPlatformLink = fetchUserPlatformLink;
/**
 * Get the platform site public config
 * @function getPlatformConfigSitePublic
 * @memberof ibldmplatform
 * @see https://github.com/ibleducation/ibl-dm-pro/blob/v2/USAGE.md#platform-site-config-update
 *
 * @param {Object} data
 * @param {String} data.key Tenant key
 * @param {callback} successCb
 * @param {callback} errorCb
 *
 * @example <caption>Get the platform site public config</caption>
 * This usage:
 *
 * ```js
 * ibldmplatform.getPlatformConfigSitePublic(
 *    {key: "tenanta"},
 *    (data) => console.log(data),
 *    (error) => console.error(error)
 * )
 * ```
 */
function getPlatformConfigSitePublic(data, successCb, errorCb) {
    http.get(config_1.default.IBL_DM.GET_PLATFORM_SITE_PUBLIC_CONFIG, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, false);
}
exports.getPlatformConfigSitePublic = getPlatformConfigSitePublic;
function updateUserProgramStatus(data, successCb, errorCb) {
    http.post(config_1.default.IBL_DM.UPDATE_USER_PROGRAM_STATUS, data, (data) => {
        successCb && successCb(data);
    }, (error) => {
        errorCb && errorCb(error);
    }, true, 'IBL_DM_URL');
}
exports.updateUserProgramStatus = updateUserProgramStatus;
