import HttpService from "../http";
import endpoint from "../config";

const http = new HttpService();

export function getOrgProducts(data , callback = null ) {
    http.get(
        endpoint.IBL_AXD.GET_ORG_FINANCE_PRODUCTS,
        data,
        (data) => {
            if (callback) {
                callback(data);
            }
        },
        (error) => {
            console.error("Error occurred:", error);
        }
    );
}

export function getOrgProductOrders(data, callback = null) {
    http.get(
        endpoint.IBL_AXD.GET_ORG_FINANCE_PRODUCT_ORDERS(data.product_slug),
        data,
        (data) => {
            if (callback) {
                callback(data);
            }
        },
        (error) => {
            console.error("Error occurred:", error);
        }
    );
}

export function getOrgProductSalesOverTime(data, callback = null ) {
    http.get(
        endpoint.IBL_AXD.GET_ORG_PRODUCT_SALES_OVER_TIME,
        data,
        (data) => {
            if (callback) {
                callback(data);
            }
        },
        (error) => {
            console.error("Error occurred:", error);
        }

    );
}
